/* Change this file to get your personal Porfolio */
function calculateExperience(year) {
  const date = new Date(`${year}-01-01`);
  const now = new Date();
  const dateCalculated = now.getFullYear() - date.getFullYear();
  return dateCalculated;
}

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello",
  title2: "Montotox",
  logo_name: "Montoto.(x)",
  nickname: "montotox",
  full_name: "Ricardo Coronel",
  subTitle:
    "Lead Engineer, Frontend Developer, UX/UI Designer & self-taught 🤓. Always learning 📚.",
  resumeLink:
    "https://drive.google.com/file/d/1JNVvEbTTAgGxUsCwyMq6xg5LRPkpL6P9/view?usp=sharing",
  resumeLink2:
    "https://drive.google.com/file/d/1hxtyO8qFuq4Y67ZXSrV_PJKttHDixhRk/view?usp=sharing",
  mail: "mailto:montotox@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/montotox",
  linkedin: "https://www.linkedin.com/in/ricardo-jose-coronel/",
  gmail: "montotox@gmail.com",
  facebook: "https://www.facebook.com/ricardo.jose.coronel/",
  instagram: "https://www.instagram.com/montoto.x/",
  dribbble: "https://dribbble.com/rcoronel",
  behance: "https://www.behance.net/ricardocoronel6",
};

const skills = {
  data: [
    {
      title: "Tech Leadership",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Architecting and implementing scalable cloud solutions across multiple platforms",
        "⚡ Leading technical teams in agile environments, fostering innovation and continuous improvement",
        "⚡ Designing and overseeing robust CI/CD pipelines for efficient software delivery",
        "⚡ Developing technical roadmaps aligned with business objectives and emerging technologies",
        "⚡ Ensuring compliance with industry standards and regulations (GDPR, ISO 27001, etc.)",
        "⚡ Facilitating effective communication between technical and non-technical stakeholders",
        "⚡ Leveraging project management and collaboration tools to streamline workflows and enhance productivity",
      ],
      softwareSkills: [
        {
          skillName: "Miro",
          fontAwesomeClassname: "simple-icons:miro",
          style: {
            color: "#050038",
          },
        },
        {
          skillName: "Notion",
          fontAwesomeClassname: "simple-icons:notion",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Slack",
          fontAwesomeClassname: "simple-icons:slack",
          style: {
            color: "#4A154B",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#181717",
          },
        },
      ],
    },
    {
      title: "Frontend Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Architecting and building high-performance, responsive web applications using ReactJS and NextJS",
        "⚡ Implementing server-side rendering and static site generation for optimal performance and SEO",
        "⚡ Leveraging TypeScript for robust, type-safe code in large-scale applications",
        "⚡ Utilizing state management solutions like Redux or Zustand for complex application states",
        "⚡ Creating beautiful, responsive UIs with advanced CSS frameworks such as TailwindCSS, Shadcn, Chakra UI, and Material-UI",
      ],
      softwareSkills: [
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#000",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Qwik",
          fontAwesomeClassname: "simple-icons:qwik",
          style: {
            color: "#AC7EF4",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Redux",
          fontAwesomeClassname: "simple-icons:redux",
          style: {
            color: "#764ABC",
          },
        },
        {
          skillName: "Shadcn CSS",
          fontAwesomeClassname: "simple-icons:shadcnui",
          style: {
            color: "#000",
          },
        },
        {
          skillName: "TailwindCSS",
          fontAwesomeClassname: "simple-icons:tailwindcss",
          style: {
            color: "#06B6D4",
          },
        },
        {
          skillName: "Chakra UI",
          fontAwesomeClassname: "simple-icons:chakraui",
          style: {
            color: "#319795",
          },
        },
        {
          skillName: "Material-UI",
          fontAwesomeClassname: "simple-icons:mui",
          style: {
            color: "#0081CB",
          },
        },
        {
          skillName: "SASS",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Linux",
          fontAwesomeClassname: "simple-icons:linux",
          style: {
            color: "#FCC624",
          },
        },
        {
          skillName: "Apple",
          fontAwesomeClassname: "simple-icons:apple",
          style: {
            color: "#C4C4C4",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },
    {
      title: "UX/UI Design & Researcher",
      fileName: "DesignImg",
      skills: [
        "⚡ Create user stories, personas, storyboards, prototypes and wireframes",
        "⚡ Problem-solving, design thinking",
        "⚡ Creative and analytical approach",
        "⚡ Business know-how: Understanding of business metrics and the ability to translate company goals and objectives into digital experiences",
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#C4C4C4",
          },
        },
        {
          skillName: "Gimp",
          fontAwesomeClassname: "simple-icons:gimp",
          style: {
            color: "#5C5543",
          },
        },
        {
          skillName: "Miro",
          fontAwesomeClassname: "simple-icons:miro",
          style: {
            color: "#050038",
          },
        },
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#31A8FF",
          },
        },
        {
          skillName: "Google Analytics",
          fontAwesomeClassname: "simple-icons:googleanalytics",
          style: {
            color: "#E37400",
          },
        },
        {
          skillName: "Hotjar",
          fontAwesomeClassname: "simple-icons:hotjar",
          style: {
            color: "#FD3A5C",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "UX Designer",
      subtitle: "UX/UI Designer & UX Researcher",
      logo_path: "google.png",
      alt_name: "Google Academy - Coursera",
      duration: "2019 - 2020",
      descriptions: [
        "⚡ Developing personas, user stories, and user journey maps.",
        "⚡ Conducting usability studies.",
        "⚡ Creating wireframes and prototypes. Testing and iterating on designs.",
      ],
      website_link: "https://grow.google/uxdesign/",
    },
    {
      title: "Fullstack Developer",
      subtitle: "Java",
      logo_path: "dh.png",
      alt_name: "Digital House",
      duration: "2019 - 2020",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Maven, Security, Spring Boot, etc.",
        "⚡ I have implemented several projects based on Spring Boot.",
      ],
      website_link: "https://www.digitalhouse.com",
    },
    {
      title: "Electronics Engineer",
      subtitle: "Electronic & Telecommunications",
      logo_path: "um.png",
      alt_name: "Universidad de Mendoza",
      duration: "2005 - 2012",
      descriptions: [
        "⚡ I have studied maths, electronics & business management.",
      ],
      website_link: "https://um.edu.ar",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Fullstack Dev",
      subtitle: "NodeJS + ReactJS + GraphQL",
      logo_path: "udemy.png",
      certificate_link:
        "https://drive.google.com/file/d/1veHhpCVlqozOW-R6LfIUKmcBmGkQJzfW/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#BD3F2B",
    },
    {
      title: "Fullstack Dev",
      subtitle: "Java + Thymeleaf",
      logo_path: "dh.png",
      certificate_link:
        "https://drive.google.com/file/d/1VlBSSEoGJhHXJLKN_U6NU7wI7PWh-rbv/view?usp=sharing",
      alt_name: "Digital House",
      color_code: "#89BD06",
    },
    {
      title: "Advanced Google Analytics",
      subtitle: "Google Academy",
      logo_path: "google.png",
      certificate_link:
        "https://analytics.google.com/analytics/academy/certificate/SAbFk1jVSg6b0abyTRaiXA",
      alt_name: "Google academy",
      color_code: "#BD2B78",
    },

    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Volunteership",
  description: `With ${calculateExperience(
    2015
  )} years in the tech industry, I've evolved from a UX Designer to a Full Stack Developer, and now a CTO. My ${calculateExperience(
    2017
  )}+ years as a Frontend Developer specializing in ReactJS have been complemented by roles as a startup founder, project manager, and technical leader. For the past ${calculateExperience(
    2022
  )} years, I've been spearheading advanced tech projects as a CTO, integrating AI and overseeing development across various platforms. My expertise spans from UX/UI design to architecting complex solutions using NextJS, Python Django, and ensuring AWS security. I've led teams in developing native Android/iOS apps and creating innovative products with machine learning. As an investor and mentor, I've contributed to the growth of startups in diverse fields. My passion for knowledge sharing has led me to teach ReactJS and mentor in lean startup methodologies. Currently seeking new challenges to further push the boundaries of technology and innovation in sustainable and impactful projects.`,
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Chief Technology Officer",
          company: "Ciclogreen - (Spain)",
          company_url: "https://ciclogreen.com",
          logo_path: "ciclo.png",
          duration: "Jan 2022 - Present",
          location: "Work from Home",
          description:
            "Spearheading advanced tech projects with AI integration. Architecting solutions using NextJS, Python Django, and ensuring AWS security. Overseeing native Android/iOS app development for sustainable urban mobility.",
          color: "#000",
        },
        {
          title: "Technical Project Leader",
          company: "Chep - (Global)",
          company_url: "https://chep.com",
          logo_path: "chep.png",
          duration: "Jul 2023 - Present",
          location: "Work from Home",
          description:
            "Technical Project Leader. Create new product with machine learning & AI.",
          color: "#000",
        },
        {
          title: "Project Manager and Head Frontend",
          company: "Chep - (Global)",
          company_url: "https://chep.com",
          logo_path: "chep.png",
          duration: "Nov 2022 - Jul 2023",
          location: "Work from Home",
          description:
            "Project Manager and Lead Frontend Team. Create new product with machine learning & AI.",
          color: "#000",
        },
        {
          title: "Head Frontend Developer & UX/UI",
          company: "Ciclogreen - (Spain)",
          company_url: "https://ciclogreen.com",
          logo_path: "ciclo.png",
          duration: "Mar 2022 - Jan 2024",
          location: "Work from Home",
          description:
            "Head Frontend ReactJS & UX/UI in startup. Create new dashboard and tools.",
          color: "#000",
        },
        {
          title: "Investor, Frontend Developer & UX/UI",
          company: "Gobeltrip - (Spain)",
          company_url: "https://gobeltrip.com",
          logo_path: "gobel.png",
          duration: "Jan 2022 - Dec 2024",
          location: "Work from Home",
          description:
            "Investor, Frontend ReactJS & UX/UI in startup. Create main app, migrating from Angutar to ReactJS.",
          color: "#000",
        },
        {
          title: "ReactJS Teacher",
          company: "Socratech - (Spain)",
          company_url: "https://socratech.es",
          logo_path: "socra.png",
          duration: "Dec 2021 - Dec 2022",
          location: "Work from Home",
          description: "Bootcamp ReactJS teacher",
          color: "#000",
        },
        {
          title: "Frontend Developer ReactJS",
          company: "Doctor-i - (Spain)",
          company_url: "https://doctori.com",
          logo_path: "dr-i.png",
          duration: "Dec 2021 - Apr 2022",
          location: "Work from Home",
          description:
            "ReactJS Developer in a litle team, but big responsabilities.",
          color: "#000",
        },
        {
          title: "Director of UX",
          company: "iBanking - (Argentina)",
          company_url: "https://ibanking.netlify.app",
          logo_path: "ibk.png",
          duration: "Jan 2020 - Aug 2021",
          location: "Work from Home",
          description:
            "Research and documents findings, creates consumer scenarios, and creates strategy documents and content to supply to the UX department personnel as well as collaborating product departments",
          color: "#000",
        },
        {
          title: "UX Designer",
          company: "Neurabox - (Argentina)",
          company_url: "https://neurabox.io/",
          logo_path: "neura.png",
          duration: "Jul 2017 - Aug 2021",
          location: "Mendoza, Argentina",
          description:
            "Considering the human-computer interaction (HCI) element of a design. UX/UI Designer for fintech PWA. Frontend ReactJS developer. Running user testing. Defining interaction models, user task flows, and UI specifications. Communicating scenarios, end-to-end experiences, interaction models, and screen designs to other people working on a product. Developing and maintaining design wireframes and specifications",
          color: "#000",
        },
        {
          title: "Full Stack Developer",
          company: "Freelancer - (Global)",
          company_url: "https://montotox.com/",
          logo_path: "montotox.png",
          duration: "Oct 2020 - Present",
          location: "Barcelona, Spain",
          description:
            "Work as freelancer in fullstack projects. The primary stack: NodeJS, Express, ReactJS, React Native, Typescript, Redux, GraphQL, MongoDB.",
          color: "#000",
        },
        {
          title: "CEO",
          company: "Neurabox - (Argentina)",
          company_url: "https://neurabox.io/",
          logo_path: "neura.png",
          duration: "Feb 2017 - Aug 2021",
          location: "Mendoza, Argentina",
          description:
            "CEO from a technology startup. Team leader and business strategy.",
          color: "#000",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Tech Mentor",
          company: "HACK UDC - (Spain)",
          company_url: "https://hackudc.gpul.org/",
          logo_path: "hackudc.png",
          duration: "Feb 2025",
          location: "Universidad de A Coruña",
          description:
            "Mentored participants in ReactJS, NextJS, Python, SQL databases, Supabase, and AI, assisting with project planning, issue resolution, and development challenges.",
          color: "#000",
        },
        ,
        {
          title: "Teacher web-designer",
          company: "FJU Universitarios - (Argentina)",
          company_url: "https://fjuargentina.com/",
          logo_path: "fju.png",
          duration: "May 2021 - Aug 2021",
          location: "Work from Home",
          description:
            "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
          color: "#000",
        },
        {
          title: "Mentor",
          company: "Socialab - (Global)",
          company_url: "https://socialabglobal.socialab.com/",
          logo_path: "cdcuenta.jpg",
          duration: "Apr 2020",
          location: "Work from Home",
          description:
            "Mentorship in lean startup, business model, golden circle, lean canvas, UX/UI, empathy map, design thinking, customer journey maps.",
          color: "#000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Below are some of my UX/UI designs and my frontend projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "montotox.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. I will be happy to receive your comments",
  },
  blogSection: {
    title: "About me",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://twitter.com/Harikrushn9",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Mindful Content",
      url: "https://github.com/montotox/mindful-content-frontend",
      description:
        "Artificial intelligence to analyze movies and determine if they meet specific diversity and representation standards, such as the Bechdel Test.",
      languages: [
        {
          name: "NextJS",
          iconifyClass: "akar-icons:nextjs-fill",
        },
        {
          name: "Artificial Intelligence",
          iconifyClass: "hugeicons:artificial-intelligence-04",
        },
        {
          name: "Shadcn CSS",
          iconifyClass: "simple-icons:shadcnui",
        },
      ],
    },
    {
      id: "1",
      name: "Design UX/UI Boarding Pass",
      url:
        "https://dribbble.com/shots/16504639-Evolution-Boarding-Pass?utm_source=Clipboard_Shot&utm_campaign=rcoronel&utm_content=Evolution%20Boarding%20Pass&utm_medium=Social_Share&utm_source=Clipboard_Shot&utm_campaign=rcoronel&utm_content=Evolution%20Boarding%20Pass&utm_medium=Social_Share",
      description: "Design UX/UI Boarding Pass - Process to final mockup.",
      languages: [
        {
          name: "Figma",
          iconifyClass: "logos-figma",
        },
        {
          name: "Material UI",
          iconifyClass: "logos:material-ui",
        },
        {
          name: "Inkscape",
          iconifyClass: "cib:inkscape",
        },
      ],
    },
    {
      id: "2",
      name: "Redesign landing page",
      url:
        "https://www.figma.com/file/ko7dtp2FENzmO2m38Hg96b/AuraQuantic?node-id=0%3A1",
      description: "Redesign landing page with Material Design for Desktop",
      languages: [
        {
          name: "Figma",
          iconifyClass: "logos-figma",
        },
        {
          name: "Gimp",
          iconifyClass: "cib:gimp",
        },
        {
          name: "Inkscape",
          iconifyClass: "cib:inkscape",
        },
      ],
    },
    {
      id: "3",
      name: "Design UI App",
      url:
        "https://www.figma.com/file/1agYGvBJl4zxGjOywzfstP/Design-UI-iParking?node-id=0%3A1",
      description: "Design UI App mobility/fintech.",
      languages: [
        {
          name: "Figma",
          iconifyClass: "logos-figma",
        },
        {
          name: "Material UI",
          iconifyClass: "logos:material-ui",
        },
        {
          name: "Inkscape",
          iconifyClass: "cib:inkscape",
        },
      ],
    },
    {
      id: "4",
      name: "Design UI Checkout",
      url:
        "https://www.figma.com/file/9Z2JmrkuyfqH1mEAiiD3rV/Design-UI-iBanking?node-id=0%3A1",
      description: "Design UI checkout fintech.",
      languages: [
        {
          name: "Figma",
          iconifyClass: "logos-figma",
        },
        {
          name: "Material UI",
          iconifyClass: "logos:material-ui",
        },
        {
          name: "Inkscape",
          iconifyClass: "cib:inkscape",
        },
      ],
    },
    {
      id: "5",
      name: "Design UI mailing",
      url:
        "https://www.figma.com/file/J8U1vXTjm9deRYINCX5UHz/iParking-email-template?node-id=0%3A1",
      description: "Design UI mailing to mobility app.",
      languages: [
        {
          name: "Figma",
          iconifyClass: "logos-figma",
        },
        {
          name: "Material UI",
          iconifyClass: "logos:material-ui",
        },
        {
          name: "Inkscape",
          iconifyClass: "cib:inkscape",
        },
      ],
    },
    {
      id: "6",
      name: "Instotox",
      url: "https://github.com/montotox/instotox/",
      description: "Social network like instagram. Used semantic-ui for React.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "SCSS",
          iconifyClass: "vscode-icons:file-type-scss2",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
        {
          name: "MongoDB",
          iconifyClass: "vscode-icons:file-type-mongo",
        },
      ],
    },
    {
      id: "7",
      name: "Landing page iBanking",
      url: "https://ibanking.netlify.app/",
      description: "A React business page.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "SCSS",
          iconifyClass: "vscode-icons:file-type-scss2",
        },
        {
          name: "Material UI",
          iconifyClass: "logos:material-ui",
        },
      ],
    },
    {
      id: "8",
      name: "Landing page iParking",
      url: "https://iparking.com.ar/",
      description: "A simple business page.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Bootstrap",
          iconifyClass: "logos-bootstrap",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "9",
      name: "SDKs for fintech",
      url: "https://github.com/montotox/ibk-node",
      description:
        "SDKs for integrate a checkout in other webapp with NodeJS, PHP or Python.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
      ],
    },
    {
      id: "10",
      name: "E-commerce",
      url: "https://github.com/montotox/Proyecto-Final/",
      description:
        "A fullstack project for e-commerce code in Java and Thymeleaf.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Maven",
          iconifyClass: "vscode-icons:file-type-maven",
        },
        {
          name: "Spring Boot",
          iconifyClass: "bx:bxl-spring-boot",
        },
        {
          name: "MySQL",
          iconifyClass: "cib:mysql",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Thymeleaf",
          iconifyClass: "simple-icons:thymeleaf",
        },
      ],
    },
    {
      id: "11",
      name: "Personal Portfolio",
      url: "https://github.com/montotox/portfolio",
      description: "Personal portfolio with ReactJS.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "SCSS",
          iconifyClass: "vscode-icons:file-type-scss2",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
